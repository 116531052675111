<template>
  <b-container>
    <b-row>
      <h1 class="notFound">
        404
      </h1>
    </b-row>
  </b-container>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style lang="scss">
    .notFound {
        position: absolute;
        top: 10%;
        font-size: 55pt;
    }
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("b-row", [
        _c("h1", { staticClass: "notFound" }, [_vm._v("\n      404\n    ")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }